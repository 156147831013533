import {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from 'next'
import ErrorPage from 'next/error'
import { usePathname } from 'next/navigation'
import { Grid } from '@/components/Grid'
import { fetchIndexDrupal } from '@/components/List/services/api'
import { Node } from '@/components/Node'
import { BundleDrupalNodeNextPages } from '@/components/Node/components/NextPage/viewmodes/Full/types'
import {
  NODE,
  NODE_EVENT,
  NODE_LANDING,
  NODE_PAGES,
  NODE_PLACE,
  NODE_PLACE_BASE,
  NODE_ROUTE,
  NODE_TOURIST_PLAN,
} from '@/components/Node/constants'
import { BundleDrupalNode } from '@/components/Node/types'
import { Paragraph } from '@/components/Paragraph'
import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import { MOCK_HERO } from '@/components/Paragraph/components/Hero/mocks'
import {
  PARAGRAPH_EXPERIENCE,
  PARAGRAPH_PLACEHOLDER_ITINERARY_MAP,
} from '@/components/Paragraph/constants'
import { ParagraphType } from '@/components/Paragraph/types'
import { PublicLayout } from '@/components/PublicLayout'
import { TERM_CUSTOM_EXPERIENCE } from '@/components/TaxonomyTerm/constants'
import {
  VIEW_MODE_CARD_ACTIVITY,
  VIEW_MODE_CARD_BASIC,
  VIEW_MODE_TEASER_CAROUSEL_3COL,
} from '@/content/constants'
import { drupal } from '@/lib/drupal'
import { getParams } from '@/lib/get-params'
import { fetchMegaMenu } from '@/lib/utils/fetchMegaMenu'
import { getBlockOfInterestParams } from '@/lib/utils/getBlockIOfnterestParams'
import { getNextPagesProps } from '@/lib/utils/getNextPagesProps'
import { randomElement } from '@/lib/utils/random-element'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { DrupalNode } from 'next-drupal'
import { EventJsonLd, NextSeo } from 'next-seo'

const EntityPage: NextPage = ({
  entity,
  footerMenu,
  footerMenuExtras,
  megamenu,
  ofInterest,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const pathname = usePathname()
  const entityType = entity?.type.split('--')[0]

  const isHomepage = pathname === '/'

  const IS_CREATE_YOUR_ITINERARY =
    entity?.type === NODE_LANDING &&
    !!entity.paragraphs?.find(
      (paragraph) => paragraph.type === PARAGRAPH_PLACEHOLDER_ITINERARY_MAP
    )

  const conditionalTranslationKeys = {
    ofInterest: 'node.ofInterest',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  switch (entityType) {
    case NODE:
      return (
        <>
          {!!entity.metatags?.EventJsonLd && (
            <EventJsonLd {...entity.metatags.EventJsonLd} />
          )}
          <NextSeo {...entity.metatags} />
          <PublicLayout
            megamenu={megamenu}
            title={entity.title}
            isHome={isHomepage}
            footerMenu={footerMenu}
            footerMenuExtras={footerMenuExtras}
            image={
              entity.computed_image ||
              entity.media ||
              entity.image ||
              entity.image_cover
            }
            isCreateYourItinerary={IS_CREATE_YOUR_ITINERARY}
            breadcrumbs={entity.breadcrumbs}
            entity={entity}
          >
            {isHomepage && (
              <>
                <Paragraph paragraph={MOCK_HERO('default') as ParagraphType} />
                <span id="jump-header"></span>
              </>
            )}
            <Node node={entity as BundleDrupalNode} />
            {!!ofInterest?.length && (
              <div className="py-16 bg-neutral-gray-100">
                <Grid title={translations.ofInterest} redLine={true} cols={3}>
                  {ofInterest.map((content: BundleDrupalNode, index) => (
                    <li key={index}>
                      <Node
                        node={{
                          ...content,
                          viewMode:
                            entity.type === NODE_EVENT
                              ? VIEW_MODE_CARD_ACTIVITY
                              : entity.type === NODE_TOURIST_PLAN
                              ? VIEW_MODE_TEASER_CAROUSEL_3COL
                              : VIEW_MODE_CARD_BASIC,
                        }}
                        key={content.id}
                      />
                    </li>
                  ))}
                </Grid>
              </div>
            )}
          </PublicLayout>
        </>
      )
    default:
      return <ErrorPage statusCode={404} />
  }
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  try {
    const localeOptions = {
      locale: context.locale ?? 'es',
      defaultLocale: context.defaultLocale ?? 'es',
    }
    context.res.setHeader(
      'Cache-Control',
      'public, max-age=300, s-maxage=300, stale-while-revalidate=86400, stale-if-error=86400'
    )

    //saul: cuando quiten el parche de redireccion de idiomas
    //const path = await drupalTranslateRoutes(context)
    //Borrar este dejar el de arriba
    const path = await drupal.translatePathFromContext(context)

    console.log(
      '🚀 ~ getStaticProps ~ context:',
      path?.jsonapi?.resourceName,
      context.params?.slug
    )

    if (!path?.jsonapi?.resourceName) {
      throw new Error('El recurso solicitado no existe o no está disponible.')
      return {
        notFound: true,
      }
    }

    if (
      context.params?.slug?.length === 1 &&
      context.params?.slug?.[0] === 'home'
    ) {
      return {
        redirect: {
          destination:
            path.entity.langcode === 'es' ? '/' : `/${path.entity.langcode}/`,
          permanent: true,
        },
      }
    }

    // Redireccion para cuando no existe la página en el idioma seleccionado.
    if (
      context.locale !== path?.entity.langcode &&
      path?.entity.langcode === 'es'
    ) {
      return {
        redirect: {
          destination: `/es${path.entity.path}`,
          permanent: true,
        },
      }
    }

    // Redireccion para cambios de path por parte del drupal.
    if (
      typeof context.params?.slug !== 'undefined' &&
      typeof context.params?.slug !== 'string' &&
      context.locale !== 'es' &&
      `/${context.locale}/${context.params?.slug?.join('/')}` !==
        path.entity.path
    ) {
      return {
        redirect: {
          destination: path.entity.path,
          permanent: true,
        },
      }
    }

    const entity = await drupal.getResourceFromContext<DrupalNode>(
      path,
      context,
      {
        params: getParams(path.jsonapi.resourceName)?.getQueryObject(),
        ...localeOptions,
      }
    )

    if (
      !!!entity ||
      (!context.preview && entity?.moderation_state === 'unpublished')
    ) {
      throw new Error('El recurso solicitado no existe o no está disponible.')
      return {
        notFound: true,
      }
    }
    if (
      context.params?.slug &&
      typeof context.params?.slug !== 'string' &&
      path.entity.path !==
        `${
          context.locale !== 'es' ? `/${context.locale}` : ''
        }/${context.params?.slug?.join('/')}`
    ) {
      return {
        redirect: {
          destination: path.entity.path,
          permanent: true,
        },
      }
    }

    const eventCategories: string[] = []
    if (entity.type === NODE_EVENT) {
      const MUNICIPALITY_EVENT = entity.term_municipality?.id
      eventCategories.push(...entity.term_categories.map((name) => name?.name))
      if (!!MUNICIPALITY_EVENT) {
        entity.surrounding_areas = await drupal.getResourceCollection(
          NODE_PLACE,
          {
            params: getParams(NODE_PLACE_BASE)
              .addFilter('term_municipality.id', MUNICIPALITY_EVENT)
              .addFilter('status', '1')
              .addPageLimit(6)
              .getQueryObject(),
            ...localeOptions,
          }
        )
      }
    }

    if (entity.type === NODE_ROUTE) {
      entity.nearby_routes.forEach((route) => {
        route.nearby_routes = []
        route.gpx = null
        route.content_tourist_points = []
      })
    }

    if (entity.type === NODE_TOURIST_PLAN) {
      const MUNICIPALITY_TOURIST_PLAN = entity.term_municipality?.id

      if (!!MUNICIPALITY_TOURIST_PLAN) {
        entity.surrounding_areas = await drupal.getResourceCollection(
          NODE_PLACE,
          {
            params: getParams(NODE_PLACE_BASE)
              .addFilter('term_municipality.id', MUNICIPALITY_TOURIST_PLAN)
              .addPageLimit(6)
              .getQueryObject(),
            ...localeOptions,
          }
        )
      }
    }

    if (entity.type === NODE_LANDING) {
      const types: string[] = entity.paragraphs.map((p) => p.type)
      const indexExperience = types.indexOf(PARAGRAPH_EXPERIENCE)
      if (indexExperience !== -1) {
        const taxonomies = await drupal.getResourceCollection(
          TERM_CUSTOM_EXPERIENCE,
          {
            params: getParams(TERM_CUSTOM_EXPERIENCE).getQueryObject(),
            ...localeOptions,
          }
        )
        entity.paragraphs[indexExperience].taxonomies = taxonomies
      }

      const indexItinerary = types.indexOf(PARAGRAPH_PLACEHOLDER_ITINERARY_MAP)
      if (indexItinerary !== -1) {
        const response = await fetchIndexDrupal(
          {
            page: 1,
            per_page: 50,
            activeFilters: [{ type: 'preload', values: { value: '1' } }],
          },
          LIST_TYPE_ITINERARY,
          true
        )

        entity.paragraphs[indexItinerary].itineraries = response.results
        entity.paragraphs[indexItinerary].facets = response.facets
      }
    }

    if (entity.type === NODE_PAGES) {
      await getNextPagesProps(
        entity as BundleDrupalNodeNextPages,
        localeOptions
      )
    }

    const ofInterest =
      entity.path.alias !== '/home' && entity.type !== NODE_PAGES
        ? await drupal.getResourceCollection<BundleDrupalNode[]>(
            !!entity.interesting_block && entity.type === NODE_LANDING
              ? `node--${entity.interesting_block}`
              : entity.type,
            {
              params: getBlockOfInterestParams(entity),
              ...localeOptions,
            }
          )
        : null

    const footerMenu = await drupal.getMenu('footer', { ...localeOptions })
    const footerMenuExtras = await drupal.getMenu('footer-extras', {
      ...localeOptions,
    })
    const megamenu = await fetchMegaMenu(localeOptions)

    return {
      props: {
        messages: (await import(`@/content/locales/${context.locale}.json`))
          .default,
        entity: entity,
        footerMenu: footerMenu.tree,
        footerMenuExtras: footerMenuExtras.tree,
        megamenu: megamenu ?? {},
        ofInterest: randomElement(ofInterest, 3),
      },
    }
  } catch (e) {
    console.error('Error en la renderización del servidor:', e)
    return {
      notFound: true,
    }
  }
}

export default EntityPage
