import { MOCK_MEDIA_IMAGE } from '@/components/Media/components/Image/mocks'
import { DrupalMediaVideo } from '@/components/Media/components/Video/types'
import { DrupalFile } from 'next-drupal'

export const MOCK_HERO = (paragraphViewMode: string) => ({
  id: 'ca74dbe8-2a82-42da-bd29-aee68492fb1d',
  type: 'paragraph--hero',
  title: 'Lorem ipsum dolor',
  subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  paragraph_view_mode: paragraphViewMode,
  drupal_internal__id: 1,
  drupal_internal__revision_id: 1,
  video: MOCK_MEDIA(),
  image: { ...MOCK_MEDIA_IMAGE(), image: '/madrid_mobile.webp' },
})

export const MOCK_MEDIA = (): DrupalMediaVideo => ({
  id: 'ca74dbe8-2a82-42da-bd29-hd128492fb1d',
  type: 'media--video',
  langcode: '',
  status: true,
  drupal_internal__mid: '',
  drupal_internal__vid: '',
  changed: '',
  created: '',
  name: 'Lorem ipsum dolor',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ullamcorper purus vel quam consectetur blandit. Suspendisse consequat imperdiet lobortis. Nam eu dolor et ligula dapibus scelerisque quis at ipsum.',
  link: {
    title: 'Conoce más',
    uri: 'https://www.google.es',
    options: {
      attributes: {
        target: '_black',
      },
    },
  },
  video: MOCK_FILE,
  links: [],
})

const MOCK_FILE: DrupalFile = {
  type: 'media--file',
  id: 'b2bd9ad6-9cc6-49d7-90b5-b3027382a4442',
  drupal_internal__fid: '1892736',
  langcode: 'es',
  filename: 'video.webm',
  uri: {
    value: 'public://0000-00/file_example_MP4_1280_10MG.mp4',
    /* url: 'madrid_video.mp4', */
    url: 'https://www.comunidad.madrid/uploads/turismo/241105-turismo-comunidad-madrid2.mp4',
  },
  filemime: 'video/mp4',
  filesize: 0,
  status: true,
  created: '0000-00-00T00:00:00+00:00',
  changed: '0000-00-00T00:00:00+00:00',
  links: {},
}
